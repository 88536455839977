import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';
import { GameContext } from '../entities/GameContext'
import logo from '../statics/images/kandolab-logo.png';
import { Grid, Button, Paper } from '@material-ui/core';

import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';

function GameSelect() {
    const { setGameType, 
            setPlayerAPoint, 
            setPlayerBPoint,
            setCurrentMatch,
            setCurrentGame,
            setStarted,
            setPlayerATimeout,
            setPlayerBTimeout,
            setPlayerAName, 
            setPlayerBName,
            setDoubleMatchNumber,
            setDoubleInitialConfig,
            setGroupTeamConfig,
            setPlayerLabel,setMatchNumber,
            setGameTypeLabel, 
        } = useContext(GameContext);
    const classes = useStyles();

    const reset = (playerANameStart, playerBNameStart, doubleMatch, doubleConfig) => {
        setPlayerAPoint({0:{0:0}})
        setPlayerBPoint({0:{0:0}})
        setCurrentMatch(0)
        setCurrentGame(0);
        setStarted(false);
        setPlayerATimeout({0:false});
        setPlayerBTimeout({0:false});
        setPlayerAName(playerANameStart)
        setPlayerBName(playerBNameStart)
        setDoubleMatchNumber(doubleMatch)
        setDoubleInitialConfig(doubleConfig)
    };

    const handleGroupModificado = (event) => {
        setGroupTeamConfig([["A1","B2",[0,3],[0,4]],["A2","B1",[1,4],[1,3]],["A3","B3",[2],[2]]])
        setPlayerLabel({
            0:["A1","B2"],
            1:["A2","B1"],
            2:["A3","B3"],
            3:["A1","B1"],
            4:["A2","B2"],
        })
        setGameTypeLabel("Swaythiling Modificado")
        setMatchNumber(5)
        setGameType(1);
        reset({},{},-1,null);
    };

    const handleGroupNoDouble= (event) => {
        setGroupTeamConfig([["A","Y",[0,3],[0,4]],["B","X",[1,5],[1,3]],["C","Z",[2,4],[2,5]]])
        setPlayerLabel({
            0:["A","Y"],
            1:["B","X"],
            2:["C","Z"],
            3:["A","X"],
            4:["C","Y"],
            5:["B","Z"],
        })
        setGameTypeLabel("Swaythiling Reducido, Sin Dobles")
        setMatchNumber(6)
        setGameType(1);
        reset({},{},-1,null);
    }

    const handleGroupDouble4thGame = (event) => {
        setGroupTeamConfig([["A","Y",[0,4],[0,5]],["B","X",[1,6],[1,4]],["C","Z",[2,5],[2,6]]])
        setPlayerLabel({
            0:["A","Y"],
            1:["B","X"],
            2:["C","Z"],
            3:["",""],
            4:["A","X"],
            5:["C","Y"],
            6:["B","Z"],
        })
        setGameTypeLabel("Swaythiling Reducido, Dobles en el Partido 4")
        setMatchNumber(7)
        setGameType(1);
        reset({3:"Dobles ABC"},{3:"Dobles XYZ"},3, null);
    };

    const handleGroupDouble7thGame = (event) => {
        // ["A","Y",[0,3],[2,4]] => [LEFT_LABEL, RIGHT_LABEL, LEFT_PLAYER_MATCHES, RIGHT_LAYER_MATCHES]
        // Basically, means A (which is player 0) will play game 0 and 3, Y (which is player 2 of B) will player game 2 and 4
        setGroupTeamConfig([["A","Y",[0,3],[0,4]],["B","X",[1,5],[1,3]],["C","Z",[2,4],[2,5]]])
        setPlayerLabel({
            0:["A","Y"],
            1:["B","X"],
            2:["C","Z"],
            3:["A","X"],
            4:["C","Y"],
            5:["B","Z"],
            6:["",""],
        })
        setGameTypeLabel("Swaythiling Reducido, Dobles en el Partido 7")
        setMatchNumber(7)
        setGameType(1);
        reset({6:"Dobles ABC"},{6:"Dobles XYZ"}, 6, null);
    };

    const handleGroup2Persons = (event) => {
        setGroupTeamConfig([["A","X",[0,3],[0,4]],["B","Y",[1,4],[1,3]]])
        setPlayerLabel({
            0:["A","X"],
            1:["B","Y"],
            2:["A|B","X|Y"],
            3:["A","Y"],
            4:["B","X"],
        })
        setGameTypeLabel("Equipo de 2 con Dobles")
        setMatchNumber(5)
        setGameType(1);
        reset({2:"AB"},{2:"XY"},2,{teamA: [0,1], teamB: [0,1]});
    }

    const handleIndividual = (event) => {
        setGameTypeLabel("Individual")
        setGameType(0);
        reset({},{}, -1, null);
    };

    const handleWoldCup = () => {
        setGroupTeamConfig([["A","X",[0,3],[0,4]],["B","Y",[1,1],[1,3]],["C","Z",[2,4],[2,2]]])
        setPlayerLabel({
            0:["A","X"],
            1:["B","Y"],
            2:["C","Z"],
            3:["A","Y"],
            4:["C","X"],
        })
        setGameTypeLabel("Copa de Mundo")
        setMatchNumber(5)
        setGameType(1);
        reset({},{},-1, null);
    }

    const handleOlympicGame = () => {
        setGroupTeamConfig([["A","X",[1,3],[1,4]],["B","Y",[4,4],[3,3]],["C","Z",[2,2],[2,2]]])
        setPlayerLabel({
            0:["B|C","Y|Z"],
            1:["A","X"],
            2:["C","Z"],
            3:["A","Y"],
            4:["B","X"],
        })
        setGameTypeLabel("Olimpiada")
        setMatchNumber(5)
        setGameType(1);
        reset({0:"BC"},{0:"YZ"}, 0, {teamA: [4,2], teamB: [3,2]});
    }
    return (
        <div className={classes.pageContent}  >     
            <div>
                <Grid item xs={12} className={classes.content}>
                    <h1><img className={classes.logoImg} src={logo} width="40px"/>Kando LAB</h1>
                </Grid>
                <Grid container className={classes.content} spacing={0} justify="center">
                    <Grid container xs={12} spacing={2} justify="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.button}
                                onClick={()=>{handleIndividual()}} 
                                endIcon={<PersonIcon />}>
                                Inidividuales
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.button}
                                onClick={()=>{handleOlympicGame()}} 
                                endIcon={<GroupIcon />}>
                                Olimpico
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.button}
                                onClick={()=>{handleWoldCup()}} 
                                endIcon={<GroupIcon />}>
                                Copa de Mundo
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.button}
                                onClick={()=>{handleGroup2Persons()}} 
                                endIcon={<GroupIcon />}>
                                Equipo de 2 con Dobles
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                onClick={()=>{handleGroupNoDouble()}} 
                                endIcon={<GroupIcon />}>
                                Swaythling Sin Dobles
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                onClick={()=>{handleGroupDouble4thGame()}} 
                                endIcon={<GroupIcon />}>
                                Swaythling Dobles en Partido 4
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                onClick={()=>{handleGroupDouble7thGame()}} 
                                endIcon={<GroupIcon />}>
                                Swaythling Dobles en Partido 7
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                size="large"
                                className={classes.button}
                                onClick={()=>{handleGroupModificado()}} 
                                endIcon={<GroupIcon />}>
                                Swaythling Modificado
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="center"  className={classes.copyright}>
                        &#xa9; 2021 KandoLab.com
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>)
}

export default GameSelect;

const useStyles = makeStyles((theme) => ({
    pageContent:{
        //padding: theme.spacing(0, 2),
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
    },
    logoImg:{
        paddingRight:"5px"
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "30px"
    },
    buttonGrid: {
        paddingTop: "20px"
    },
    button: {
        borderRadius: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
        height: 50,
    }
}));