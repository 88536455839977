import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import '@fontsource/roboto';
import Grid from '@material-ui/core/Grid';
import { GameContext } from '../entities/GameContext';

import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import PlayerNames from './components/PlayerNames';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import StopIcon from '@material-ui/icons/Stop';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Snackbar } from '@material-ui/core';

import Countdown from 'react-countdown';

import MuiAlert from '@material-ui/lab/Alert';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      paddingLeft: "3px",paddingRight: "3px",
      paddingTop: "2px",paddingBottom: "2px",
    },
    body: {
      fontSize: 12,
      borderWidth: 1, borderColor: '#DDDDDD',borderStyle: 'solid',
      marginLeft: "1px",marginRight: "1px",
      paddingLeft: "3px",paddingRight: "3px",
      paddingTop: "2px",paddingBottom: "2px",
      textTransform: 'uppercase',
    },
  }))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

function ControlDashboard(props) {
    const classes = useStyles();

    let h = {backgroundColor: '#ff50c2', color: 'white'}


    var status = props.status || {}
    var matches = status["matches"] || []

    var currentMatch =  status["currentMatch"] || 0
    var currentGame = status["currentGame"] || 0
    var gameId = currentMatch * 10 + currentGame

    var teamAName = status["teamA"] || ""
    var teamBName = status["teamB"] || ""
    var teamAColour = status['teamAColour'] || "#ffffff"

    var teamBColour = status['teamBColour'] || "#ffffff"
    var matchSummary = {}
    var totalSummary = {}

    var teamAResult = 0
    var teamBResult = 0

    matches.forEach((m,i) => {
        let p = m["points"] || []
        let A = 0
        let B = 0
        p.forEach((p, j) => {
            let a = p["playerA"] || 0
            let b = p["playerB"] || 0
            if ((a >= 11 || b >= 11) && (Math.abs(a-b)>=2)){
                if (a>b){
                    A += 1
                }else{
                    B += 1
                }
            }
        })
        matchSummary[i] = [A, B]
        if (i<currentMatch) {
            if (A > B) {
                teamAResult += 1
            }else{
                teamBResult += 1
            }
        }
        totalSummary[i] = [teamAResult,teamBResult]
    })

    console.log("Control DashBoard: ", props.status, currentMatch, currentGame)

    return (
        <Grid container className={classes.root} justify="center">
            <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" >  
                <PlayerNames 
                        leftPlayerName={teamAName}
                        leftInputLabel="EquipoABC"
                        rightPlayerName={teamBName}
                        rightInputLabel="EquipoXYZ"
                        leftPlayerColour={teamAColour}
                        rightPlayerColour={teamBColour}
                        nameOnly={false}
                        leftPlayerGamePoint={teamAResult}
                        rightPlayerGamePoint={teamBResult}
                    />   
            </Grid>

            <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" >     
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} size="small" aria-label="customized table">
                        <colgroup>
                            <col width="3%" />
                            <col width="15%" />
                            <col width="3%" />
                            <col width="15%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left"></StyledTableCell>
                                <StyledTableCell align="left">Nombre</StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="left">Nombre</StyledTableCell>
                                <StyledTableCell align="center">J1</StyledTableCell>
                                <StyledTableCell align="center">J2</StyledTableCell>
                                <StyledTableCell align="center">J3</StyledTableCell>
                                <StyledTableCell align="center">J4</StyledTableCell>
                                <StyledTableCell align="center">J5</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">Total</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {matches.map((m, i) => {
                                return(
                                    <StyledTableRow key={"label" + i}>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="left">{m["playerA"]}</StyledTableCell>
                                        <StyledTableCell align="right">{""}</StyledTableCell>
                                        <StyledTableCell align="left">{m["playerB"]}</StyledTableCell>
                                        <StyledTableCell align="center" 
                                            style={(i==currentMatch&&currentGame==0)?h:{}}>
                                                {(i*10+0 > gameId)? "": (""+m["points"][0]["playerA"]+"-"+m["points"][0]["playerB"])}
                                        </StyledTableCell>
                                        <StyledTableCell align="center"
                                            style={(i==currentMatch&&currentGame==1)?h:{}}>
                                                {(i*10+1 > gameId)? "": (""+m["points"][1]["playerA"]+"-"+m["points"][1]["playerB"])}
                                        </StyledTableCell>
                                        <StyledTableCell align="center"
                                            style={(i==currentMatch&&currentGame==2)?h:{}}>
                                                {(i*10+2 > gameId)? "": (""+m["points"][2]["playerA"]+"-"+m["points"][2]["playerB"])}
                                        </StyledTableCell>
                                        <StyledTableCell align="center"
                                            style={(i==currentMatch&&currentGame==3)?h:{}}>
                                               {(i*10+3 > gameId)? "": (""+m["points"][3]["playerA"]+"-"+m["points"][3]["playerB"])}
                                        </StyledTableCell>
                                        <StyledTableCell align="center"
                                            style={(i==currentMatch&&currentGame==4)?h:{}}>
                                                {(i*10+4 > gameId)? "": (""+m["points"][3]["playerA"]+"-"+m["points"][3]["playerB"])}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {i<=currentMatch?"" + matchSummary[i][0] + "-" + matchSummary[i][1] : ""}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {i<currentMatch?"" + totalSummary[i][0] + "-" + totalSummary[i][1] : ""}
                                        </StyledTableCell>
                                    </StyledTableRow> )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container xs={12} className={classes.content} justify="center" direction="column">
                <Grid container className={classes.content} spacing={0} justify="center">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="center" className={classes.startButtonGrid}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={()=>{}} 
                                className={classes.actionButton}
                                endIcon={<StopIcon />}>
                                Final
                            </Button> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.content} justify="center" direction="column">
                <Grid container className={classes.content} spacing={0} justify="center">
                    <Grid item xs={12}>
                        <Grid container justify="center"  className={classes.copyright}>
                        &#xa9; 2021 KandoLab.com
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default ControlDashboard;

const useStyles = makeStyles((theme) => ({
    pageContent:{
        //margin: "0px",
        marginTop: "15px",
    },
    appBar: { 
        background: '#000' 
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
        marginTop: "0px"
    },
    root: {
        flexGrow: 1,
        marginTop: "10px",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "10px"
    },
    setNumberGrid:{
        paddingTop: "20px",
    },
    startButtonGrid: {
        paddingTop: "20px"
    },
    startButton: {
        borderRadius: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
    },
    playerTextField: {
        heigh: "15px",
    },
    logoImg:{
        filter: "invert(1)",
        paddingRight:"5px"
    },
    appbar: {
        background : '#000000',
    },
    formControlLabel: {
        fontSize:'12px'
    },
    actionButton: {
        //height: "12vw",
        //width: "40vw",
        paddingLeft: "5px",
        paddingRight: "5px",
        borderRadius: "20px",
        fontWeight: "bold",
        marginLeft: "1vw",
        marginRight: "1vw",
        [theme.breakpoints.up('sm')]: {
            height: "40px",
            width: "210px",
            boarderRadius: "20px"
        },
    },
    tableContainer : {
        marginLeft: "20px",
        marginRight: "20px",
    },
    finishDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 0, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    actionButtonGridFinish: {
        marginTop: "20px"
    }
  }));