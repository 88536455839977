import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import '@fontsource/roboto';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment';
import Hidden from '@material-ui/core/Hidden';

import { ReactComponent as TShirt } from '../../statics/images/t-shirt.svg';

function PlayerNames(props) {
    const classes = useStyles();

    const leftPlayerLabel = props.leftPlayerLabel || null
    const rightPlayerLabel = props.rightPlayerLabel || null
    
    const leftPlayerName = props.leftPlayerName || ""
    const rightPlayerName = props.rightPlayerName || ""

    const leftPlayerColour = props.leftPlayerColour || "#ff0000"
    const rightPlayerColour = props.rightPlayerColour || "#ff0000"

    const leftPlayerGamePoint = props.leftPlayerGamePoint || 0
    const rightPlayerGamePoint = props.rightPlayerGamePoint || 0

    const leftInputLabel = props.leftInputLabel || null
    const rightInputLabel = props.rightInputLabel || null

    const leftInputHandle = props.leftInputHandle || null
    const rightInputHandle = props.rightInputHandle || null
    

    const nameOnly = props.nameOnly 

    const hideTShirt = props.hideTShirt || false

    return (
        <Grid container spacing={0} justify="center" direction="row">
            <Grid container xs={nameOnly?6:6} justify="center"  direction="row"> 
                <Grid container spacing={0}  xs={nameOnly?12:11} justify="center" direction="row">
                    {leftPlayerLabel!=null && <Grid item ><p className={classes.label}>{leftPlayerLabel}</p></Grid>}
                        <Grid item>
                            <TextField 
                                disabled={leftInputHandle==null}
                                size="small" 
                                label={leftInputLabel}
                                id="input-player-a-name" 
                                value={leftPlayerName}
                                variant="outlined"
                                onChange={(e)=>{leftInputHandle(e.target.value)}}
                                inputProps={{
                                    style: {textAlign:"right", color:"black", textTransform: 'uppercase'}
                                }}
                                className={classes.playerLeftName}/>
                        </Grid>
                        {!hideTShirt && <Grid item><Hidden xsDown><TShirt width='30px' fill={leftPlayerColour} stroke="black" /> </Hidden></Grid>}
                </Grid>
                
                {!nameOnly && 
                <Grid container xs={1} spacing={0} justify="center">
                    <div className={classes.gamePointContainer}>
                        <div className={classes.gamePoint}>
                            {leftPlayerGamePoint}
                        </div>
                    </div>
                </Grid>}
            </Grid>
            <Grid container  xs={nameOnly?6:6} spacing={0} justify="center">
                {!nameOnly && 
                <Grid container xs={1} spacing={0} justify="center">
                    <div className={classes.gamePointContainer}>
                        <div className={classes.gamePoint}>
                            {rightPlayerGamePoint}
                        </div>
                    </div>
                </Grid>}
                <Grid container xs={nameOnly?12:11} spacing={0} justify="center">
                    {!hideTShirt && <Grid item><Hidden xsDown><TShirt width='30px' fill={rightPlayerColour} stroke="black"/> </Hidden></Grid>}
                    <Grid item >
                        <TextField 
                            disabled={rightInputHandle==null}
                            size="small" 
                            label={rightInputLabel}
                            id="input-player-b-name" 
                            value={rightPlayerName}
                            variant="outlined"
                            onChange={(e)=>{rightInputHandle(e.target.value)}}
                            inputProps={{style: {textAlign:"left", color:"black", textTransform: 'uppercase'}}}
                            className={classes.playerRightName}/>
                    </Grid>
                    {rightPlayerLabel!=null &&<Grid item ><p className={classes.label}>{rightPlayerLabel}</p></Grid>}
                </Grid>
                
            </Grid>
        </Grid>
    );

}
export default PlayerNames;

const useStyles = makeStyles((theme) => ({
    gamePointContainer: {
        textAlign: "center",
    },
    gamePoint:{
        backgroundColor: "black",
        border: "1px solid white",
        width: "5vw",
        fontSize: "4vw",
        fontWeight: "bold",
        color: "white",
        textAlign: "center",
        marginTop: "2px",
        paddingTop: "5px",
        paddingBottom: "5px",
        [theme.breakpoints.up('sm')]: {
            width: "20px",
            fontSize: "18px",
            marginLeft: "0px",
            marginRight: "0px",
            paddingTop: "6px",
            marginTop: "2px",
            paddingBottom: "6px",
        },
    },
    playerLeftName: {
        marginRight: "2px"
    },    
    playerRightName: {
        marginLeft: "2px"
    },
    label:{
        marginTop: "10px",
        marginLeft: "5px",
        marginRight: "5px",
    }
}));