import React, { useContext, useEffect, useState, useRef } from 'react';
import {withRouter, Redirect} from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';
import { GameContext } from '../entities/GameContext'
import logo from '../statics/images/kandolab-logo.png';
import { Grid, Button, Paper, IconButton } from '@material-ui/core';

import { TextField } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Home from './Home'
import Alert from './components/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { ReactComponent as TShirt } from '../statics/images/t-shirt.svg';
import { ExpandLessSharp } from '@material-ui/icons';
import md5 from "md5";
import ControlDashboard from './ControlDashboard';
import patrocinadores from '../statics/images/patrocinadores_resized.gif';

import {useSessionStorage} from "react-use-storage";

import Flags from "country-flag-icons/react/3x2";
import { hasFlag } from 'country-flag-icons'

import { Collapse, Zoom, Fade, Grow, Slide } from '@material-ui/core';

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVEL : window.API_URL

function computeTeamState(gameStatus) {
    var tAResult = 0
    var tBResult = 0
 
    let currentMatch = gameStatus.currentMatch
    let currentGame = gameStatus.currentGame

    for (let match=0 ; (match<gameStatus.matches.length && match<=currentMatch) ; match ++){
        var aGPoint = 0
        var bGPoint = 0
        var pAGPoint = 0
        var pBGPoint = 0
        
        for (let game=0 ; game<gameStatus.matches[match].points.length; game ++ ) {
            let pointA = gameStatus.matches[match].points[game].playerA
            let pointB = gameStatus.matches[match].points[game].playerB

            if (match === currentMatch) {
                if (game === currentGame) {
                    continue
                }
            }
            if (pointA>10 || pointB > 10){
                if (Math.abs(pointA - pointB) >= 2){
                    if (pointA > pointB){
                        aGPoint += 1
                    }else{
                        bGPoint += 1
                    }
                }
            }
            //console.log("Match: ", match, "Game: ", game, "A: ", aGPoint, "B: ", bGPoint, "T:", setNumberToWin)
        }
        let setNumberToWin = parseInt(gameStatus.matches[match].points.length / 2 )
        if (aGPoint > setNumberToWin || bGPoint > setNumberToWin) {
            if (aGPoint > bGPoint) {
                tAResult += 1
            }else{
                tBResult += 1
            }
        }
        if (match === currentMatch) {
            pAGPoint = aGPoint
            pBGPoint = bGPoint
        }
    }
    return {gamePointA: pAGPoint, 
            gamePointB: pBGPoint, 
            teamPointA: tAResult,
            teamPointB: tBResult}
}

function Feed(props) {
    

    const [ id, setId ] = useState(null)
    const [ message, setMessage ] = useState(null)
    const [ gameStatus, setGameStatus] = useState(null)
    const [ gameStatusVisible, setGameStatusVisible ] = useState(true)
    const [ teamStatusVisible, setTeamStatusVisible ] = useState(true)
    const [ teamAFlag, setTeamAFlag ] = useState("")
    const [ teamBFlag, setTeamBFlag ] = useState("")
    const [ timeVisible, setTimeVisible ] = useState(false)
    const [ sponsorVisible, setSponsorVisible ] = useState(false)
    const [ showServe, setShowServe ] = useState(true)
    const [ showTshirt, setShowTshirt ] = useState(true)
    const [ currentTime, setCurrentTime ] = useState("")
    const [ lastUpdateTime, setLastUpdateTime ] = useState(0)
    const [ imageUrl, setImageUrl ] = useState("")

    const [primaryColor, setPrimaryColor] = useSessionStorage("primary_color", "#111111")
    const [secondaryColor, setSecondaryColor] = useSessionStorage("secondary_color", "#bbbbbb")
    const [backgroundColor, setBackgroundColor] = useSessionStorage("background_color", "#00ff00")
    const [sponsorImageSize, setSponsorImageSize] = useSessionStorage("sponsor_image_size", 200)
    
    const ws = useRef(null)
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    var theme = {
        "backgroundColor": backgroundColor,
        "primaryColor" : primaryColor,
        "secondaryColor" : secondaryColor,
        "playerNameWidth" : 308,
        "width": 1920,
        "height": 1080,
        "border": 1080 * 0.05,
        "scoreTotalHeigh": 125,
    }
    if (teamAFlag.length === 0 && teamBFlag.length === 0)
    {
        theme.playerNameWidth += 40;
    }
    const classes = useStyles(theme);

    useEffect(() => {
        const id = props.match.params.id
        const r =  props.match.params.r
        const pass =  props.match.params.p
        console.log("Check token: ", props.match.params, id, r, pass)
        let hash = md5("" + id + "@" + r).substring(0,5)
        if (pass == hash || !props.control)
        {
            setId(id)
        }else{
            console.log(props.match.params, hash)
        }
    }, [])

    useEffect(() => {
        if (id === null) {
            return
        }

        if (waitingToReconnect) {
            return;
        }

        var wsCurrent = null

        console.log("Let to connect to Reflector")

        if (!ws.current) {
            var base = ""
            if (baseURL.substring(0, 8) === "https://"){
                base = "wss://" + baseURL.substring(8,baseURL.length);
            }else if(baseURL.substring(0, 7) === "http://"){
                base = "ws://" + baseURL.substring(7,baseURL.length);
            }else{
                base = baseURL;
            }

            ws.current = new WebSocket(base + "api/v1/reflect/"+id);
            wsCurrent = ws.current

            ws.current.onopen = () => {
                setIsOpen(true)
                console.log("ws opened");
            }
            ws.current.onclose = () => {
                if (ws.current) {
                    console.log("ws closed by server")
                }else{
                    console.log("ws closed by component unmount")
                    return
                }
                if (waitingToReconnect) {
                    return
                }
                setIsOpen(false)
                console.log("ws closed");

                setWaitingToReconnect(true)

                setTimeout(() => setWaitingToReconnect(null), 5000);
            }
        }

        return () => {
            wsCurrent.close();
            ws.current = null;
        };
    }, [waitingToReconnect, id]);

    useEffect(() => {
        if (!props.control)
        {
            setInterval(() => {
                let d = new Date();
                let timeString = ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                setCurrentTime(timeString)
            }, 1000); 
        }
    }, [])

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = function (event) {
            const data = JSON.parse(event.data);
            let timeStamp = Date.now() / 1000
            console.log("New data:", data, timeStamp)

            if (data.type === 'matchStatus') {
                setGameStatus(data.content)
                setLastUpdateTime(timeStamp)
            }
            if (data.type === 'state') {
                if((data.content.elementState & 0x01) > 0){
                    setTeamStatusVisible(true)
                }else{
                    setTeamStatusVisible(false)
                }
                if((data.content.elementState & 0x02) > 0){
                    setGameStatusVisible(true)
                }else{
                    setGameStatusVisible(false)
                }
                if((data.content.elementState & 0x04) > 0){
                    setShowTshirt(true)
                }else{
                    setShowTshirt(false)
                }
                if((data.content.elementState & 0x08) > 0){
                    setShowServe(true)
                }else{
                    setShowServe(false)
                }
                if((data.content.elementState & 0x10) > 0){
                    setSponsorVisible(true)
                }else{
                    setSponsorVisible(false)
                }
                if((data.content.elementState & 0x20) > 0){
                    setTimeVisible(true)
                }else{
                    setTimeVisible(false)
                }

                if ("primary_color" in data.content) {
                    setPrimaryColor(data.content.primary_color)
                }
                if ("secondary_color" in data.content) {
                    setSecondaryColor(data.content.secondary_color)
                }
                if ("background_color" in data.content) {
                    setBackgroundColor(data.content.background_color)
                }
                if ("sponsor_image_size" in data.content) {
                    setSponsorImageSize(data.content.sponsor_image_size)
                }
                if ("sc_team_a_flag" in data.content) {
                    setTeamAFlag(data.content.sc_team_a_flag)
                }
                if ("sc_team_b_flag" in data.content) {
                    setTeamBFlag(data.content.sc_team_b_flag)
                }
                if ("layer0_url" in data.content) {
                    let url = baseURL + "api/v2/cameras/" + id + "/layer/"+0 + "?filename="+data.content.layer0_url + "&preview=true"
                    console.log("layout0 url: ", url)
                    setImageUrl(url)
                }
                setLastUpdateTime(timeStamp)
            }
        };
    }, [isOpen]);

    var currentMath = 0
    var currentGame = 0 
    var playerA = "PLAYER A"
    var playerB = "PLAYER B"
    var pointA = 0
    var pointB = 0
    var playerAServeFirstInThisGame = false
    var playerAServe = false
    var teamAColor = null
    var teamBColor = null
    var teamAName = ""
    var teamBName = ""
    var gamePointA  = 0
    var gamePointB  = 0
    var teamPointA  = 0
    var teamPointB  = 0
    var playerATimeout = false
    var playerBTimeout = false
    var innactive = false
    var current = Date.now()/1000
    

    if (gameStatus !==  null)
    {
        currentMath = gameStatus.currentMatch || 0
        currentGame = gameStatus.currentGame || 0
        
        playerA = gameStatus.matches[currentMath].playerA.substring(0,19)
        playerB = gameStatus.matches[currentMath].playerB.substring(0,19)
        pointA = gameStatus.matches[currentMath].points[currentGame].playerA
        pointB = gameStatus.matches[currentMath].points[currentGame].playerB

        playerATimeout = gameStatus.matches[currentMath].playerATimeout
        playerBTimeout = gameStatus.matches[currentMath].playerBTimeout
        

        teamAColor = gameStatus.teamAColour
        teamBColor = gameStatus.teamBColour
        teamAName = gameStatus.teamA
        teamBName = gameStatus.teamB

        let playerAServeFirstInThisMatch = gameStatus.matches[currentMath].playerAServe
        if ((currentGame%2) === 1) {
            playerAServeFirstInThisGame = !playerAServeFirstInThisMatch
        }else{
            playerAServeFirstInThisGame = playerAServeFirstInThisMatch
        }
        if (pointA < 10 || pointB < 10) {
            if ((pointA + pointB) % 4 === 0 || (pointA + pointB) % 4 === 1) {
                playerAServe = playerAServeFirstInThisGame
            }else{
                playerAServe = !playerAServeFirstInThisGame
            }
        }else{
            if ((pointA + pointB) % 2 === 0) {
                playerAServe = playerAServeFirstInThisGame
            }else{
                playerAServe = !playerAServeFirstInThisGame
            }
        }
        var general = computeTeamState(gameStatus)
        gamePointA = general.gamePointA
        gamePointB = general.gamePointB
        teamPointA = general.teamPointA
        teamPointB = general.teamPointB

        if ((current - lastUpdateTime) > (5*60)) {
            innactive = true
        }
    }

    var hasFlagA = false
    var FlagA = null
    
    if (teamAFlag.length > 0) {
        if (teamAFlag.startsWith("#")){
            if (hasFlag(teamAFlag.substring(1))) {
                hasFlagA = true
                FlagA = Flags[teamAFlag.substring(1)]
            }
        }
    }
    var hasFlagB = false
    var FlagB = null
    
    if (teamBFlag.length > 0) {
        if (teamBFlag.startsWith("#")){
            if (hasFlag(teamBFlag.substring(1))) {
                hasFlagB = true
                FlagB = Flags[teamBFlag.substring(1)]
            }
        }
    }
    var showFlag = true
    if (teamAFlag.length===0 && teamBFlag.length ===0) {
        showFlag = false
    }

    if (id) {
        if (props.control)
        {
            return(
                <ControlDashboard status={gameStatus}/>
            )
        }else
        {
            return (
            <div className={classes.pageContent}   >
                <Slide direction="down" in={sponsorVisible}>
                    <div className={classes.adsContainer} >
                        <img src={imageUrl} alt="" className={classes.adsImage} style={{"width": sponsorImageSize}}/>
                    </div>
                </Slide>
        
                
                <div className={classes.currentGame} >
                    <Zoom in={!innactive && teamStatusVisible && gameStatus !==  null && teamAName.length > 0 && teamBName.length > 0} >
                        <div className={classes.teamItem}>
                            <div className={classes.teamName} 
                                style={{
                                    textAlign: "right",
                                    borderTopLeftRadius: "3px",
                                    borderBottomLeftRadius: "3px"}}>
                                {teamAName}</div> 
                            <div className={classes.teamMatchStatus} style={{marginRight: "4px"}}>{teamPointA}</div>
                            <div className={classes.teamMatchStatus}>{teamPointB}</div>
                            <div className={classes.teamName} style={{
                                borderTopRightRadius: "3px",
                                borderBottomRightRadius: "3px",}}>
                                {teamBName}</div> 
                        </div>
                    </Zoom>
                    <Slide direction="up" in={!innactive && gameStatusVisible && gameStatus !==  null}>
                        <div>
                            <div className={classes.item}>
                                <div className={classes.tShirt}>{ teamAColor!==null && showTshirt &&<TShirt width='32px' fill={teamAColor} stroke="black" /> } </div>
                                <div className={classes.playerName} >
                                    {playerA}
                                </div>
                                {showFlag && <div className={hasFlagA?classes.flag:classes.flagText}>{hasFlagA?<FlagA style={{width:"28px"}}/>:teamAFlag}</div>}
                                <div className={classes.serve}>{(showServe && playerAServe)?<ArrowLeftIcon fontSize='inherit'/>:" "}</div>
                                <div className={classes.matchStatus}>{gamePointA}</div>
                                <div className={classes.points}>{pointA}</div>
                                {playerATimeout && <div className={classes.timeoutCardActive}>T</div>}
                            </div>
                            <div className={classes.item}>
                                <div className={classes.tShirt}>{teamBColor!==null && showTshirt && <TShirt width='32px' fill={teamBColor} stroke="black" /> } </div>
                                <div className={classes.playerName} >
                                    {playerB}
                                </div> 
                                {showFlag && <div className={hasFlagB?classes.flag:classes.flagText}>{hasFlagB?<FlagB style={{width:"28px"}}/>:teamBFlag}</div>}
                                <div className={classes.serve}>{!showServe || playerAServe?" ":<ArrowLeftIcon fontSize='inherit'/>}</div> 
                                <div className={classes.matchStatus}>{gamePointB}</div>
                                <div className={classes.points}>{pointB}</div>
                                {playerBTimeout && <div className={classes.timeoutCardActive}>T</div>}
                            </div>
                        </div>
                    </Slide>
                </div>
                <div className={classes.timeContainer} >
                    <Slide direction="down" in={timeVisible}>
                        <div className={classes.item}>
                            <div className={classes.timeItem} >
                                {currentTime}
                            </div>
                        </div>
                    </Slide>
                </div>
                
            </div>
            )
        }
    }else{
        return (
            <div className={classes.pageContent}   >
                Not valid url
            </div>
        )
    }
}

export default withRouter(Feed);

const useStyles = makeStyles((theme) => ({
    pageContent: {
        //padding: theme.spacing(0, 2),
        backgroundColor: ( props ) => props.backgroundColor, //"#00ff00",
        width: ( props ) => props.width, //'1920px',
        height: ( props ) => props.height, //'1080px',
        margin: '0px'
    },
    adsContainer:{
        position: "fixed",
        left: ( props ) => props.border, //'30px',
        top: ( props ) => props.border, //'30px',
    },
    adsImage: {
        width: "200px"
    },
    imageContainer:{
        position: "fixed",
        left: "0%",
        top: "0%",
        width: "100%",
        heigh: "100%",
    },
    image: {
        width: "100%",
        transition: "opacity 3s linear",
    },
    currentGame: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        left: ( props ) => props.border, //'30px',
        top: ( props ) => props.height - props.border - props.scoreTotalHeigh, //'30px',

        fontSize: "32px",
    },
    
    item: {
        marginTop: '5px',
        height: '38px',
    },
    tShirt:{
        backgroundColor:  ( props ) => props.backgroundColor, //"#00ff00",
        width: '38px',
        height: '36px',
        display: "inline",

        float: 'left',
        margin: 0,
        padding: '4px 2px 2px 0px',
    },
    playerName:{
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        width: (props) => props.playerNameWidth, //'308px',
        height: '33px',

        fontSize: "22px",

        display: "inline",
        textTransform: 'uppercase',
        wordBreak: 'break-all',
        textOverflow: 'ellipsis', 

        borderTopLeftRadius: "3px",
        borderBottomLeftRadius: "3px",

        float: 'left',
        padding: '7px 5px 0px 10px',
    },
    teamItem: {
        marginTop: '0px',
        marginBottom: '0px',
        marginLeft: '40px',
        height: '35px',
    },
    teamName:{
        backgroundColor: (props) => props.secondaryColor, //'#111111', //'#003f72',
        color: (props) => props.primaryColor, //'#bbbbbb', //'#dae5ed',
        width: '201px',
        height: '17px',

        display: "inline",
        textTransform: 'uppercase',
        marginTop: "6px",
        fontWeight: "bold",
        fontSize: "14px",

        float: 'left',
        padding: '6px 5px 5px 5px',
    },

    teamMatchStatus: {
        backgroundColor:  (props) => props.primaryColor, // '#bbbbbb', //'#dae5ed',
        color: (props) => props.secondaryColor, //'#111111', //'#003f72',
        width: '25px',
        height: '23px',

        display: "inline",
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: "2px",
        
        float: 'left',
        textAlign: 'center',
        padding: '7px 5px 5px 5px',
    },

    timeContainer: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        width: 125,

        left: ( props ) => props.width - props.border - 125, //'30px',
        top: ( props ) => props.border, //'30px',

        fontSize: "32px",
    },

    timeItem: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        width: '125px',
        height: '35px',
        fontSize: "30px",

        display: "inline",
        borderRadius: "3px",

        float: 'right',
        padding: '2px 5px 5px 20px',
    },
    serve: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        width: '23px',
        height: '36px',

        display: "inline",

        float: 'left',
        padding: '2px 5px 2px 10px'
    },
    flag: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        width: '40px',
        height: '36px',

        display: "inline",

        float: 'left',
        padding: '0px 0px 4px 5px'
    },
    flagText: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        width: '40px',
        height: '33px',

        display: "inline",
        fontSize: "20px",

        float: 'left',
        padding: '7px 0px 0px 5px'
    },
    matchStatus: {
        backgroundColor:  (props) => props.secondaryColor, // '#bbbbbb', //'#dae5ed',
        color: (props) => props.primaryColor, //'#111111', //'#003f72',
        width: '36px',
        height: '35px',

        display: "inline",

        fontWeight: "bold",
        fontSize: "28px",
        
        float: 'left',
        textAlign: 'center',
        padding: '3px 5px 2px 5px'
    },
    points:{
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, // '#bbbbbb', //'#dae5ed',
        width: '36px',
        height: '35px',

        display: "inline",
        borderTopRightRadius: "3px",
        borderBottomRightRadius: "3px",
        fontSize: "28px",
        
        float: 'left',
        textAlign: 'center',
        padding: '3px 5px 2px 5px'
    },
    
    timeoutCardActive: {
        backgroundColor:  (props) => props.secondaryColor, // '#bbbbbb', //'#dae5ed',
        color: (props) => props.primaryColor, //'#111111', //'#003f72',
        width: '24px',
        height: '24px',

        fontSize: "24px",
        fontWeight: 'bold',
        display: "inline",
        borderRadius: "3px",
        
        float: 'left',
        textAlign: 'center',
        marginTop: 0,
        marginLeft: 4,
        padding: '0px 0px 6px 0px',
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
    },
    logoImg:{
        paddingRight:"5px"
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "30px"
    },
    buttonGrid: {
        paddingTop: "20px"
    },
    button: {
        borderRadius: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
        marginTop:"20px",
    }
}));