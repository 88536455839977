const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVEL : window.API_URL

export async function syncStatus(id, body) {
    return fetch(baseURL + '/api/v1/direct/'+id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(response => {
        if (response.ok) {
            return null
        }else{
            throw new Error("Wrong Response Code")
        }
    }).catch((err) => {
        throw err
    })
}


export async function getStatus(id) {
    return fetch(baseURL + '/api/v1/direct/'+id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
        if (response.ok) {
            return response.json()
        }else{
            throw new Error("Wrong Response Code")
        }
    }).catch((err) => {
        throw err
    })
}